<template>
  <div>
    <responsible-persons-add></responsible-persons-add>
  </div>
</template>

<script>
import ResponsiblePersonsAdd from "@/components/ecologist/classifiers/responsiblePersons/responsiblePersonsAdd";
export default {
  name: "responsiblePersonsAddView",
  components: { ResponsiblePersonsAdd },
};
</script>

<style scoped></style>
