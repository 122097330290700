<template>
  <div>
    <el-form
      :model="addResponsiblePersonsForm"
      :rules="rules"
      ref="formedForm"
      label-position="top"
      label-width="120px"
    >
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="Должность" prop="position">
            <el-input v-model="addResponsiblePersonsForm.position"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Фамилия Имя Отчество" prop="fullname">
            <el-input v-model="addResponsiblePersonsForm.fullname"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            prop="organization"
            label="Объект/Филиал/Подразделение/Участок"
          >
            <el-select
              style="width: 100%"
              clearable
              value-key="id"
              v-model="addResponsiblePersonsForm.organization"
              filterable
              placeholder="Выберите"
            >
              <el-option
                v-for="item in organizationHierarchyByType"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Телефон/Факс">
            <el-input
              v-model="addResponsiblePersonsForm.phoneNumber"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="Номер распорядительного документа">
            <el-select
              clearable
              class="searchPole"
              v-model="addResponsiblePersonsForm.document"
              filterable
              remote
              value-key="id"
              reserve-keyword
              placeholder="Номер документа"
              :remote-method="searchDocumentNumber"
            >
              <el-option
                v-for="item in listDocumentNumbers"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-button type="success" @click="addResp">Добавить</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notification from "@/mixins/notification";

export default {
  name: "responsiblePersonsAdd",
  mixins: [notification],
  data() {
    return {
      rules: {
        fullname: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        position: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        organization: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
      addResponsiblePersonsForm: {
        docList: "",
        pathInfo: "",
        phoneNumber: "",
        fullname: "",
        position: "",
      },
    };
  },

  computed: {
    ...mapGetters({
      organizationHierarchyByType: "GETORGANIZATIHIERARCHYBYTYPE",
      listDocumentNumbers: "GETLISTDOCUMENTNUMBERS",
    }),
  },

  methods: {
    searchDocumentNumber(query) {
      this.$store.dispatch("getDocsNumbersSearch", { query: query });
    },

    addResp() {
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("createResponsiblePerson", this.addResponsiblePersonsForm)
            .then((response) => {
              console.log(response);
              this.notification("Успешно", "Ответственный добавлен", "success");
              this.$store.dispatch("setShowModal", null);
              this.$store.dispatch("getAllResponsiblePerson", {
                page: 0,
                size: 20,
              });
            });
        } else {
          return false;
        }
      });
    },
  },

  mounted() {
    this.$store.dispatch("getOrganizationHierarchyByType", { type: 3 });
    this.searchDocumentNumber("");
  },
};
</script>

<style scoped></style>
